import React from "react";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";
import { getTranslations } from "../../Common/utils";
import { CtaButton } from "../Buttons";

const NoDataFound = ({
  noDataThumb,
  noDataTitle,
  noDataCaption,
  noDataCta,
}) => {
  return (
    <>
      <div className="noData__wrap">
        {noDataThumb ? (
          <div className="noData__thumb">
            <img
              src={`${IMAGE_BASE_URL}/${noDataThumb}?v=${IMAGE_VERSION}`}
              alt="no data found thumnail"
              loading="lazy"
            />
          </div>
        ) : (
          ""
        )}
        {noDataTitle ? (
          <h2 className="noData__title">
            <span>{noDataTitle}</span>
          </h2>
        ) : (
          ""
        )}
        {noDataCaption ? (
          <div className="noData__caption">{noDataCaption}</div>
        ) : (
          ""
        )}
        {noDataCta ? (
          <div className="noData__cta">
            <CtaButton btnCls="btn btn__link" btnText={noDataCta} />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export { NoDataFound };

import React from "react";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";

const PortfolioCard = ({
  extraClass,
  pf_dir,
  pf_thumbnail,
  pf_title,
  pf_caption,
}) => {
  return (
    <>
      <div className={`portfolio__card ${extraClass}`}>
        <div className="portfolio__thumb">
          <img
            src={`${IMAGE_BASE_URL}/${pf_dir}/${pf_thumbnail}?v=${IMAGE_VERSION}`}
            alt={pf_title}
            loading="lazy"
          />
        </div>
        <h2 className="portfolio__title">
          <span>{pf_title}</span>
        </h2>
        <h3 className="portfolio__caption">
          <span>{pf_caption}</span>
        </h3>
      </div>
    </>
  );
};

export { PortfolioCard };

import React, { useRef, useState } from "react";
import { InputField } from "../InputField";
import { CtaButton } from "../Buttons";
import { InputFieldValidation } from "../InputFieldValidation";

const FORM_SUCCESS = "success";
const FORM_DANGER = "invalid";
const TEXT_AREA_MAX_LENGTH = 500;
const NAME_MAX_LENGTH = 100;
const EMAIL_MAX_LENGTH = 100;

const Newsletter = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const [formMessageClass, setFormMessageClass] = useState("");

  function handleNameInput(e) {
    setShowSuccess(false);
    setName(e.target.value);
  }

  function handleEmailInput(e) {
    setShowSuccess(false);
    setEmail(e.target.value);
  }

  function handleMessageInput(e) {
    setShowSuccess(false);
    setMessage(e.target.value);
  }

  function isFormValid() {
    return name.trim() && email.trim() && message.trim();
  }

  const formRef = useRef(null);

  function submitForm(event) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      // console.log("Email Invalid");
      setShowSuccess(true);
      setFormMessageClass(FORM_DANGER);
      setFormMessage("Email entered is invalid");
      return;
    }

    fetch(`https://beyonddesign.cc/submit.php`, {
      method: "PUT",
      body: JSON.stringify({
        name: name.trim(),
        email: email.trim(),
        message: message.trim(),
      }),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then((res) => {
        return res.text();
      })
      .then((txt) => {
        setName("");
        setEmail("");
        setMessage("");
        setShowSuccess(true);
        setFormMessageClass(FORM_SUCCESS);
        setFormMessage("Thank you for contacting us");
      })
      .catch((err) => {
        console.error(err);
        setShowSuccess(true);
        setFormMessageClass(FORM_DANGER);
        setFormMessage("Something went wrong");
      });
    return false;
  }

  return (
    <>
      <section className="newsletter__wrap">
        <div className="main__container">
          <div className="newsletter__grid">
            <div className="newsletter__grid-item">
              <div className="newsletter__caption">
                Let’s make something amazing Together!!
              </div>
            </div>
            <div className="newsletter__grid-item">
              <h2 className="newsletter__title">
                <span>Dear Beyond Design,</span>
              </h2>
              <div className="newsletter__form">
                <div className="newsletter__form-row">
                  <div className="input ">
                    <textarea
                      placeholder="Tell us about your company and how we can help you"
                      value={message}
                      onChange={(e) => handleMessageInput(e)}
                      autoComplete="off"
                      maxLength={TEXT_AREA_MAX_LENGTH}
                    ></textarea>
                  </div>
                </div>
                <div className="newsletter__form-row">
                  <div className="newsletter__form-grid">
                    <div className="newsletter__form-item">
                      <InputField
                        inputType="text"
                        placeholderLabel="Your Name"
                        inputText={name}
                        handleInput={(e) => handleNameInput(e)}
                        extraAttri={{ name: "name", required: true }}
                        autoComplete="off"
                        maxLength={NAME_MAX_LENGTH}
                      />
                      <InputField
                        inputType="email"
                        placeholderLabel="Your Email"
                        inputText={email}
                        handleInput={(e) => handleEmailInput(e)}
                        extraAttri={{ name: "email", required: true }}
                        autoComplete="off"
                        maxLength={EMAIL_MAX_LENGTH}
                      />
                    </div>
                    <div className="newsletter__form-item">
                      <div className="newsletter__form-cta">
                        <CtaButton
                          btnCls="btn btn__primary pageclip-form__submit"
                          iconCls=""
                          btnText={`${`Send`.toUpperCase()}`}
                          btnState={!isFormValid()}
                          onClickProp={(e) => {
                            e.preventDefault();
                            // formRef.current.submit();
                            submitForm();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="newsletter__form-row newsletter__form-row--errMsg">
                  {showSuccess && (
                    <InputFieldValidation
                      extraCls={formMessageClass}
                      validationText={formMessage}
                    />
                  )}
                </div>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { Newsletter };

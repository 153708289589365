import React from "react";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";

const ServiceCard = ({
  sc_listConfig,
  sc_discriptionConfig,
  sc_dir,
  sc_thumbnail,
  sc_title,
  extraClass = "",
}) => {
  return (
    <>
      <div className={`service__card  ${extraClass}`}>
        <div className="service__grid">
          <div className="service__grid-lhs  ">
            <div className="service__thumbnail">
              <img
                src={`${IMAGE_BASE_URL}/${sc_dir}/${sc_thumbnail}?v=${IMAGE_VERSION}`}
                alt={sc_title}
                loading="lazy"
              />
            </div>
          </div>
          <div className="service__grid-rhs ">
            <h2 className="service__title">
              <span>{sc_title}</span>
            </h2>
            <div className="service__discription">
              {sc_discriptionConfig.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
            <ul className="service__list">
              {sc_listConfig.map((item, index) => (
                <li key={index}>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export { ServiceCard };

import React from "react";

const PageTitle = ({ customTag, pageTitle }) => {
  const CustomTag = customTag;
  return (
    <>
      <CustomTag className="section__heading">
        <span>{pageTitle}</span>
      </CustomTag>
    </>
  );
};

export { PageTitle };

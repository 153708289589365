import React from "react";
import { PageTitle } from "../PageTitle";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
gsap.registerPlugin(ScrollTrigger);

const HeroSliderArr = [
  {
    thumbnails: [
      { id: 1, alt: "Veera" },
      { id: 2, alt: "Fast-&-Up" },
      { id: 3, alt: "Figrr" },
      { id: 4, alt: "Solesearch" },
      { id: 5, alt: "Chef-&-Porter" },
      { id: 6, alt: "Duncay-Taylor" },
    ],
  },
  {
    extraCls: "reverse",
    thumbnails: [
      { id: 7, alt: "LetsVenture" },
      { id: 8, alt: "Gov.uk" },
      { id: 9, alt: "d'hybrid" },
      { id: 10, alt: "Aurum" },
      { id: 11, alt: "Sunschool" },
      { id: 12, alt: "360One" },
    ],
  },
];

const HeroSlider = () => {
  $(window).on("load", function () {
    // looper();
    // initSwiper();
    initMarqueeSlider();
    // initLogoSlider();
    // initBackgroundChange();
    // initIconLoop();
  });
  function initMarqueeSlider() {
    gsap.registerPlugin(ScrollTrigger);
    $(".heroBanner__row").each(function (index) {
      var container = $(this);
      var isReverse = container.hasClass("reverse");
      var $win = $(window);
      var holder = container.find(".heroBanner__thumbHolder");
      var timer;

      var speed = 0.8;
      var slides = container.find(
        `.heroBanner__thumb.heroBanner__thumb--${index}`
      );
      slides.each(function () {
        var slide = $(this);
        var clone = slide.clone();
        holder.append(clone);
      });
      var i = isReverse ? holder[0].scrollWidth / 2 : 0;
      initInternval();

      function initInternval() {
        timer = setInterval(function () {
          holder.css("transform", `translate3d(-${i}px, 0, 0)`);
          if (isReverse) {
            if (i <= 0) {
              i = holder[0].scrollWidth / 2;
            }
            i = i - speed;
          } else {
            if (i > holder[0].scrollWidth / 2) {
              i = 0;
            }
            i = i + speed;
          }
        }, 40);
      }

      container.hover(
        function () {
          clearInterval(timer);
        },
        function () {
          initInternval();
        }
      );

      var tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          markers: false,
          toggleActions: "play reverse play reverse",
          start: "top bottom",
          end: "bottom top",
          scrub: 0.25,
          onUpdate: (self) => {
            if (isReverse) {
              gsap.to(container, { x: "+" + self.progress * 30 + "%" });
            } else {
              gsap.to(container, { x: "-" + self.progress * 30 + "%" });
            }
          },
        },
      });
    });
  }

  return (
    <>
      <section className="heroBanner__wrap section__row section__props">
        <div className="main__container">
          <PageTitle
            customTag="h1"
            pageTitle={`${`a design studio for brands that CHOOSE to go beyond`.toUpperCase()}`}
          />
        </div>
        <div className="heroBanner__swiper">
          <div className="heroBanner__grid">
            {HeroSliderArr.map((item, index) => (
              <div
                key={index}
                className={`heroBanner__row ${
                  item.extraCls ? item.extraCls : ""
                }`}
              >
                <div className="heroBanner__thumbHolder">
                  {item.thumbnails.map((item, thumbIndex) => (
                    <div
                      key={thumbIndex}
                      className={`heroBanner__thumb heroBanner__thumb--${index}`}
                    >
                      <img
                        src={`${IMAGE_BASE_URL}/hp-slider-thumbnails/${item.id}.jpg?v=${IMAGE_VERSION}`}
                        alt={`${item.alt}`}
                        loading="lazy"
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export { HeroSlider };
